<template>
  <div class="modal-content">
    <div class="modal-header">
      <svg v-svg @click="$emit('closeModal')" class="close mr-2 mt-2" symbol="modal-close" size="0 0 15 15" role="info"></svg>
    </div>
    <div class="modal-body p-5">
      <h2>ID: {{ creative.id }}</h2>
      <div class="push-container p-3" v-if="creative.format === 'Web Push'">
        <div class="head">
          <div>
            <svg v-svg symbol="chrome" size="0 0 15 15" role="info"></svg>
            <small class="pl-1">Chrome • mysite.com • now </small>
          </div>
          <span class="d-block">{{ creative.adTitle }}</span>
          <span class="d-block">{{ creative.adText }}</span>
          <img :src="creative.adIcon" class="ad-icon" />
        </div>
        <img :src="creative.adImage" class="ad-image" />
        <span class="ad-btn">SETTINGS</span>
        <span class="ad-btn">MORE</span>
      </div>
    
      <div class="inpage-container text-center" v-if="creative.format === 'In-Page'">
        <component v-if="creative"
        :is="showTemplate" :imgIcon="creative.adIcon" :adTitle="creative.adTitle" :adText="creative.adText" />
         
      </div>
    </div>
  </div>
</template>

<script>
 

export default {
   
  props: {
    creative: {
      type: Object,
    },
  },
  computed:{
    showTemplate(){
       const templates = {
         'Default': () => import('@/components/InpageTemplates/Main.vue'),
         'Android': () => import('@/components/InpageTemplates/Android.vue'),
         'Black': () => import('@/components/InpageTemplates/Black.vue'),
         'Compact': () => import('@/components/InpageTemplates/Compact.vue'),
         'Dark': () => import('@/components/InpageTemplates/Dark.vue'),
         'Deep': () => import('@/components/InpageTemplates/Deep.vue'),
         'Dialog': () => import('@/components/InpageTemplates/Dialog.vue'),
         'Facebook': () => import('@/components/InpageTemplates/Facebook.vue'),
         'Float-Motion': () => import('@/components/InpageTemplates/Float-Motion.vue'),
         'Frame': () => import('@/components/InpageTemplates/Frame.vue'),
         'Grand': () => import('@/components/InpageTemplates/Grand.vue'),
         'Instagram': () => import('@/components/InpageTemplates/Instagram.vue'),
         'IOS-Dark': () => import('@/components/InpageTemplates/IOS-Dark.vue'),
         'iOS': () => import('@/components/InpageTemplates/iOS.vue'),
         'Large-1': () => import('@/components/InpageTemplates/Large-1.vue'),
         'Large-2': () => import('@/components/InpageTemplates/Large-2.vue'),
         'Large-3': () => import('@/components/InpageTemplates/Large-3.vue'),
         'Large': () => import('@/components/InpageTemplates/Large.vue'),
         'Light': () => import('@/components/InpageTemplates/Light.vue'),
         'Mac': () => import('@/components/InpageTemplates/Mac.vue'),
         'Pink(Dating)': () => import('@/components/InpageTemplates/Pink(Dating).vue'),
         'Pure-white': () => import('@/components/InpageTemplates/Pure-white.vue'),
         'Purple-gradient': () => import('@/components/InpageTemplates/Purple-gradient.vue'),
         'Ripple-effect-2': () => import('@/components/InpageTemplates/Ripple-effect-2.vue'),
         'Ripple-effect': () => import('@/components/InpageTemplates/Ripple-effect.vue'),
         'Small': () => import('@/components/InpageTemplates/Small.vue'),
         'Spin-Animation': () => import('@/components/InpageTemplates/Spin-Animation.vue'),
         'Static-display': () => import('@/components/InpageTemplates/Static-display.vue'),
         'Telegram': () => import('@/components/InpageTemplates/Telegram.vue'),
         'Twist-Notification': () => import('@/components/InpageTemplates/Twist-Notification.vue'),
         'Twitter': () => import('@/components/InpageTemplates/Twitter.vue'),
         'Whatsapp': () => import('@/components/InpageTemplates/Whatsapp.vue'),
         'White': () => import('@/components/InpageTemplates/White.vue'),
         'White(Online)': () => import('@/components/InpageTemplates/White(Online).vue'),
         'Windows': () => import('@/components/InpageTemplates/Windows.vue')
        
      };

      return templates[this.creative.template] || templates.Default;
    }
  }
};
</script>

<style lang="scss" scoped>

@import "~@/assets/css/inpage-templates.css";

.dark .push-container {
  span,small {
  color: #000;
  }
}
.push-container {
  position: relative;
  border-radius: 8px;
  background: #fff;
  max-width: 350px;
  margin: 0 auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);

  .head {
    display: flex;
    flex-flow: column wrap;
    img.ad-icon {
      position: absolute;
      right: 16px;
      height: 32px;
      width: 32px;
    }
    span {
      font-size: 13px;
      color: #3c3c3c;
      &:nth-child(2) {
         font-size: 14px;
         color: #000000;
      }
    }
  }
  img.ad-image {
    width: 100%;
    height: 200px;
  }
  .ad-btn {
    padding-top: 10px;
    padding-right: 18px;
    color: #2891e7 !important;
    display: inline-block;
  }
}
</style>
