<template>
  <vue-good-table 
    :columns="columns" 
    :rows="copyRows" 
    styleClass="table _no-wrap">
    
    <template slot="table-column" slot-scope="props">
      <span>
        {{ props.column.label ? formatLabel(props.column.label) + ":" : "" }}
      </span>
    </template>

    <template slot="table-row" slot-scope="props">
      <template v-if="props.column.field === 'id'">
        <span>{{ props.row.id || "-" }}</span>
      </template>

      <template v-if="props.column.field === 'format'">
        <span class="format-label">{{ props.row.format }}</span>
      </template>

      <template v-if="props.column.field === 'watch'">
        <svg
          v-svg
          v-if="props.row.format !== 'On-Click'"
          @click="onShowCreative(props.row)"
          :symbol="isDarkMode ? 'eye-gray':'eye-green'"
          size="0 0 15 15"
          :class="{'dark':isDarkMode}"
          class="watch-btn"
        />
        <svg
          v-svg
          v-else
          symbol="open"
          size="0 0 15 15"
          :class="{'dark':isDarkMode}"
          @click="onOpenLink(props.row.url)"
          class="watch-btn"
        />
      </template>

      <template v-if="props.column.field === 'ad'">
        <div class="d-flex" v-if="props.row.format !== 'On-Click'">
          <img v-if="props.row.format === 'Web Push'" :src="props.row.adImage" class="ad-image" />
          <img v-if="props.row.format !== 'On-Click'" :src="props.row.adIcon" class="ad-icon" />
          <div class="description d-grid" v-if="props.row.format !== 'On-Click'">
            <span>{{ props.row.adTitle }}</span>
            <small>{{ props.row.adText }}</small>
          </div>
        </div>
        <span class="campaign-link" v-else>
          <svg v-svg role="presentation" size="0 0 18 16" symbol="link"></svg>
          {{ props.row.url }}
        </span>
      </template>

      <template v-if="props.column.field === 'action'">
        <button
          class="ml-auto d-block btn btn-action"
          :class="{'locked':props.row.locked}"
          @click="onToggleLock(props.row.id, props.row.locked)"
        >
          <svg
            v-svg
            v-show="!props.row.locked"
            symbol="lock"
            size="0 0 15 15"
            role="presentation"
          />
          <svg
            v-svg
            v-show="props.row.locked"
            symbol="lock-open"
            size="0 0 15 15"
            role="presentation"
          />
         
          {{  props.row.locked ? $t("active_camp.unlock") : $t("active_camp.lock") }}
        </button>
      </template>
    </template>

    <div class="d-flex justify-content-center" slot="emptystate">
      <template v-if="isLoading">
        <div class="d-flex justify-content-center">
          <img
            :src="require('@/assets/images/rolling.svg')"
            width="50"
            alt="loader"
            class="loader"
          />
        </div>
      </template>
      <h4 v-else>{{ $t("any_results") }}</h4>
    </div>
  </vue-good-table>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
    },
    option: { type: Object, required: true },
  },
  
  watch: {
    rows(val) {
      this.copyRows = val
    }
  },
  data: () => ({
    copyRows: [],
     isDarkMode: document.body.classList.contains("dark")
  }),
   mounted() {
    this.observeBodyClass();
  },
  methods: {
     observeBodyClass() {
      const observer = new MutationObserver(() => {
        this.isDarkMode = document.body.classList.contains("dark");
      });
      observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });
    },
    formatLabel(el) {
      return this.$t(el);
    },
    onShowCreative(row) {
      this.$emit("show-creative", row);
    },
    onOpenLink(url) {
      this.$emit("open-link", url);
    },
    onToggleLock(rowId, isLocked) {

      const el = this.copyRows.find(row => row.id === rowId);
        if (el) {
          el.locked = !el.locked;
        }
      
      this.$emit("toggle-lock", rowId, isLocked);
    },
  },
};
</script>

<style scoped lang="scss">
.description {
  display: grid;
  margin-left: 14px;
  span {
    font-size: 14px;
  }
  small {
    color: rgba(151, 151, 151, 1);
    font-size: 13px;
  }
}
span.campaign-link {
  svg {
    fill: #317475;
  }
  color: #317475;
  font-weight: 400;
}

svg.watch-btn {
  cursor: pointer;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  padding: 6px;
  fill: #317475;
  border: 1px solid #317475;
  &.dark {
    border: 1px solid #323232;
    fill:#848484;
  }
}
button.btn-action {
  background: transparent;
  border: 1px solid #c62424;
  color: #c62424;
  font-weight: 400;
  transition: none;
  svg {
    fill: #c62424;
    vertical-align: baseline;
  }
  &:hover {
    color: #fff !important;
    background: #c62424 !important;
    svg {
    fill: #fff;
    }
  }
  &.locked {
    border: none;
    background: #317475;
    color: #fff;
    svg {
      fill: #fff;
    }
    &:hover {
      background: rgb(36, 92, 93) !important;
    }
  }
}

span.format-label {
  display: inline-block;
  min-width: 60px;
  text-align: center;
  border: 1px solid rgba(228, 228, 234, 1);
  background: #fff;
  color: rgba(20, 20, 20, 1);
  font-size: 12px;
  border-radius: 5px;
  padding: 6px;
}

img.ad-image {
  height: 40px;
  width: auto;
  border-radius: 4px;
}

img.ad-icon {
  height: 40px;
  width: auto;
  margin-left: 8px;
  border-radius: 4px;
}

</style>
