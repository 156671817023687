<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="d-flex align-items-center col-12 col-lg-4 col-md-4">
            <button class="back mr-2" @click="$router.push('/platforms')">
              <svg v-svg role="presentation" size="0 0 20 14" symbol="arrow-back" />
            </button>
            <h1 class="mb-0 pt-0">{{ $t("active_camp.title") }}</h1>
          </div>
          <div class="col-12 col-lg-8 col-md-8 ml-auto">
            <div class="row align-items-center">
              <div class="col-12 col-md-4 mt-3 mt-md-0 search-field">
                <input v-model="search" type="search" @keydown.enter="filterCampaigns" @blur="filterCampaigns" :placeholder="$t('search_placeholder')" />
                <button @click="filterCampaigns" class="icon-search" title="Search">
                  <svg v-svg symbol="search" size="0 0 15 16"></svg>
                </button>
              </div>

              <custom-multiselect class="col-12 col-md-4 mt-3 mt-md-0" v-model="option" :list="campaignOptions" :allowEmpty="false">
                <template v-slot:block="props">
                  <span v-if="props.value.value == -1">
                    {{formatLabel('statistic.tabs.all')}} ({{totalAll }})
                  </span>
                  <span v-else> {{ props.value.value ? formatLabel(props.value.title) + totalLocked : formatLabel(props.value.title) + totalUnLocked }}</span>
                </template>
                <template v-slot:list="props">
                   <span v-if="props.value.value == -1">
                        {{$t(formatLabel('statistic.tabs.all'))}} ({{totalAll }})
                   </span>
                  <span v-else>
                    {{ props.value.value ? formatLabel(props.value.title) + totalLocked : formatLabel(props.value.title) + totalUnLocked }}
                  </span>
                </template>
              </custom-multiselect>
              <div class="col-12 col-md-4 mt-3 mt-md-0">
                <button :disabled="rows && rows.length == 0" @click="$modal.show('showUnlockAll')" class="px-3 btn _bg-green">{{ $t("active_camp.unlock_all") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sep _h20"></div>
    <div class="c-table">
      <div class="inner">
        <active-campaigns-table
        :columns="columns"
        :rows="rows"
        :option="option" 
        :isLoading="isLoading"
        @show-creative="showCreative"
        @open-link="openLink"
        @toggle-lock="toggleLock">
        </active-campaigns-table>
        
      </div>
    </div>

    <div class="c-pagination mb-5">
      <ul class="pagination" v-if="rows && rows.length && rows.length > 0">
        <li class="page-item">
          <button @click.prevent="page--" :class="{ disabled: page <= 1 || isLoading }" :disabled="isLoading || page <= 1" class="page-link icon-prev prev" data-ci-pagination-page="2" rel="prev" :title="$t('statistic.prev')">
            <svg v-svg symbol="prev" size="0 0 8 14" />
          </button>
        </li>
        <li class="page-item">
          <button @click.prevent="page++" :class="{ disabled: page * limit >= total || isLoading }" :disabled="isLoading || page * limit >= total" class="page-link icon-next next" data-ci-pagination-page="2" rel="next" :title="$t('statistic.next')">
            <svg v-svg symbol="next" size="0 0 8 14" />
          </button>
        </li>
        <li class="page-item">{{ page === 1 ? page : limit * (page - 1) + 1 }} - {{ limit * (page - 1) + rows.length }} {{ $t("from") }} {{ total }}</li>
        <li>
          <custom-multiselect :disabled="isLoading" class="ml-3" v-model="limit" :list="optionsPagination" :allowEmpty="false">
            <template v-slot:block="props">
              <span>
                {{ props.value }}
              </span>
            </template>
            <template v-slot:list="props">
              <span>
                {{ props.value }}
              </span>
            </template>
          </custom-multiselect>
        </li>
      </ul>
    </div>

    <modal @closed="$modal.hide('showCreative')" :width="550" name="showCreative" height="auto" :scrollable="true" classes="creative-modal">
      <Creative :creative="currentCreative" @closeModal="$modal.hide('showCreative')" />
    </modal>
    <modal @closed="$modal.hide('showUnlockAll')" :width="550" name="showUnlockAll" height="auto" :scrollable="true" classes="creative-modal">
      <div class="pt-3 pb-4 px-3 mx-auto d-block text-center">
        <svg v-svg @click="$modal.hide('showUnlockAll')" class="close" symbol="modal-close" size="0 0 15 15" role="info"></svg>
        <svg v-svg symbol="lock2" v-if="!isDarkTheme" size="0 0 50 50"></svg>
        <h3 class="modal-title pt-3" v-html="$t('active_camp.unlock_confirm')"></h3>
        <button class="btn mt-5 mr-3 _bg-gray" @click="$modal.hide('showUnlockAll')">{{ $t("cancel") }}</button>
        <button class="btn mt-5 _bg-green" @click="unlockAll">{{ $t("active_camp.unlock") }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import "@/assets/css/_pagination.scss";

import serviceDomain from "@/api/serviceDomain";
import Creative from "@/components/modal/Creative.vue";
import ActiveCampaignsTable from "@/components/ActiveCampaignsTable.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Creative,
    ActiveCampaignsTable
  },
  data: () => ({
    limit: 10,
    optionsPagination: [10, 25, 50, 100],
    page: 1,
    offset: 0,
    total: null,
    totalAll: 0,
    unlocked_total: 0,
    locked_total: 0,
    search: "",
    isLoading: true,
    currentCreative: null,

    rows: [],
    columns: [
      {
        label: "ID",
        field: "id",
      },
      {
        label: "platforms.table.format",
        field: "format",
      },
      {
        field: "watch",
      },
      {
        label: "platforms.table.ad",
        field: "ad",
      },
      {
        field: "action",
      },
    ],
    campaignOptions: [
      { title: "active_camp.locked", value: 1 },
      { title: "active_camp.unlocked", value: 0 },
      { title: "all", value: -1 },
    ],
    option: { title: "all", value: -1 },
  }),
  computed: {
    ...mapGetters({
      isDarkTheme: "user/isDarkTheme",
    }),
    totalLocked() {
      return " (" + this.locked_total + ")";
    },
    totalUnLocked() {
      return " (" + this.unlocked_total + ")";
    },
    
  },
  methods: {
    formatLabel(el) {
      return this.$t(el);
    },
    showCreative(data) {
      this.currentCreative = data;
      this.$modal.show("showCreative");
    },
    openLink(url) {
      window.open(url);
    },
    unlockAll() {
      serviceDomain.unlockAllCampaigns(this.$route.params.id).then((res) => {
        if (res && res.status == 200) {
          this.$alert({
            type: "success",
            title: this.$t("sent_success"),
          });
          this.getCurrentCampaign();
        }
      });
      this.$modal.hide("showUnlockAll");
    },
    toggleLock(id, isLocked) {
      const action = isLocked == 1 ? "unlock" : "lock";
      const method = action === "unlock" ? serviceDomain.unlockCampaign : serviceDomain.lockCampaign;

      method(this.$route.params.id, id)
        .then((res) => {
          if (res && res.status === 200) {
            this.$alert({
              type: "success",
              title: this.$t("sent_success"),
              text: this.$t(`active_camp.${action}ed`),
            });
         
            if (isLocked){
              this.locked_total--;
              this.unlocked_total++
            }else {
               this.locked_total++;
              this.unlocked_total--
            }  
           

          } else {
            this.$alert({
              type: "error",
              title: this.$t("sent_error"),
            });
          }
        })
        .catch((error) => {
          console.error(`${action}Campaign failed:`, error);
          this.$alert({
            type: "error",
            title: this.$t("sent_error"),
          });
        });
    },

    filterCampaigns() {
      this.getCurrentCampaign();
    },
    getCurrentCampaign() {
       const payload = {
      id: this.$route.params?.id,
      locked: this.option.value === 0 || this.option.value === 1 ? this.option.value: -1,
      limit: this.limit,
      offset: this.search.length > 0 ? 0 : this.offset,
      search: this.search.length > 0 ? this.search : '',
    };
      let queryParams = new URLSearchParams({
      offset: String(payload.offset),
      limit: String(payload.limit),
      search: payload.search || '',
    });
      
      if (payload.locked !== -1) {
      queryParams.append('locked', String(payload.locked));
    }
        
      this.isLoading = true;

      serviceDomain
        .getCurrentCampaign(payload.id,queryParams)
        .then((res) => {
          if (res && res.status === 200 && res.data) {
            this.rows = res.data?.data.rows || [];
            this.unlocked_total = res.data?.data.unlocked_total || 0;
            this.locked_total = res.data?.data.locked_total || 0;
            this.total = res.data?.data.total || 0;
            this.totalAll = this.unlocked_total + this.locked_total
         
            
      
          } else {
            this.$alert({
              type: "error",
              title: this.$t("sent_error"),
              text: this.res?.message || this.res,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    page() {
      this.rows = [];
      this.offset = this.limit * (this.page - 1);
    },
    option() {
      this.offset = 0
      this.getCurrentCampaign();
    },
    limit() {
      this.rows = [];
      this.getCurrentCampaign();
    },
    offset(val) {
      if (val === 0 && this.page !== 1) return
      this.getCurrentCampaign();
    },
  },
  mounted() {
    this.getCurrentCampaign();
  },
};
</script>

<style scoped lang="scss">


.dark button.back {
  opacity: 0.5;
}

h3.modal-title {
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
  ::v-deep span {
    background: #d6ebeba6;
    border-radius: 10px;
    line-height: 2;
    padding: 6px;
    color: #317475;
  }
}



button.back {
  background: rgba(247, 247, 249, 1);
  border-radius: 10px;
  width: 50px;
  height: 50px;
}



.title-row h1 {
  vertical-align: middle;
  display: inline-block;
  font-size: 22px;
  @media screen and (min-width: 768px) {
    font-size: 26px;
  }
}

</style>
